import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as React from 'react';
import styles from './style.m.less';
export default function ButtonContainer({ children, className, align = 'right' }) {
    return _jsx("div", { className: classNames(styles.container, styles[`align-${align}`], className), children: children });
}
/**
 * @deprecated Please use `ButtonContainer` for anything new.
 */
export function DeprecatedButtonContainer(props) {
    const containerProps = Object.assign(Object.assign({}, props), { className: classNames('button-container', props.className) });
    return _jsx("div", Object.assign({}, containerProps));
}
DeprecatedButtonContainer.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
};
