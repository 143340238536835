import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { get } from 'lodash/fp';
import moment from 'moment';
import * as React from 'react';
import { DeprecatedButtonContainer } from '../../react/components/ButtonContainer';
import { Card, CardHeader, CardHeaderHeading, CardSection } from '../../react/components/Cards';
import style from './style.m.less';
const contentByError = [
    {
        check: error => get('name', error) === 'ChunkLoadError',
        content: {
            title: 'Loading Error',
            description: (_jsxs(_Fragment, { children: ["The application code required for this page could not be loaded. Please reload the page.", _jsx("br", {}), "If the issue persists, clear the browser cache, use private mode, or try a different browser."] })),
            background: style.internalError,
        },
    },
];
const contentByCode = {
    '-1': {
        title: 'There is no Internet Connection',
        description: "Please ensure that your internet connection is working. Due to connectivity issues we couldn't load:",
        background: undefined,
    },
    401: {
        title: 'Not Authorized',
        description: 'Maybe you need to login again to gain access to the following page:',
        background: undefined,
    },
    403: {
        title: 'Access Denied',
        description: `You don't have permission to access this page. If you've recently been granted Admin permissions,
            please log out then back in to refresh your permissions.
            If that doesn't work, please reach out to your existing administrators.`,
        background: style.accessDenied,
    },
    404: {
        title: 'Not Found',
        description: 'Sorry, the page you requested was not found.',
        background: style.notFound,
    },
    500: {
        title: 'Internal Error',
        description: 'Something went wrong internally while accessing the following page:',
        background: style.internalError,
    },
};
const getErrorCode = (errorCode) => {
    if (errorCode && errorCode in contentByCode) {
        return errorCode;
    }
    return 500;
};
const getError = ({ error, errorCode }) => {
    var _a;
    if (error instanceof Error) {
        const codeFromError = get('response.status', error);
        const code = getErrorCode(typeof codeFromError === 'string' || typeof codeFromError === 'number' ? codeFromError : errorCode);
        const content = ((_a = contentByError.find(({ check }) => check(error))) === null || _a === void 0 ? void 0 : _a.content) || contentByCode[code];
        return { code, content };
    }
    else {
        const code = getErrorCode(errorCode);
        const content = contentByCode[code];
        return { code, content };
    }
};
/**
 * Make sure not to import/use *any* references to the currentUser
 * in this component – it's used before that is available.
 */
export function RouteErrorPlain(props) {
    const { content } = getError(props);
    return (_jsx("div", { className: content.background, children: _jsx(ErrorCard, Object.assign({}, props)) }));
}
export function ErrorCard(props) {
    var _a;
    const { content, code } = getError(props);
    return (_jsxs(Card, { className: style.card, children: [_jsx(ErrorDescription, Object.assign({}, content)), _jsxs(CardSection, { children: [_jsx(PageUrl, {}), _jsxs(DeprecatedButtonContainer, { children: [(_a = props.renderInfoButton) === null || _a === void 0 ? void 0 : _a.call(props, code), _jsx("a", { className: "button save", href: "/app/home", children: "Return Home" })] })] })] }));
}
function ErrorDescription({ title, description }) {
    return (_jsxs("div", { "data-test": "route-error-description", children: [_jsx(CardHeader, { children: _jsx(CardHeaderHeading, { children: title }) }), _jsx(CardSection, { children: _jsx("p", { children: description }) })] }));
}
function PageUrl() {
    const { href } = window.location;
    const date = moment.utc().format();
    return (_jsxs("code", { className: "clearfix", children: [_jsx("span", { style: { wordWrap: 'break-word' }, children: href }), _jsxs("span", { style: { color: '#BABFC2', float: 'right' }, children: [date, " | ", __currentRev__] })] }));
}
