// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Cards-interactive-5A3Jl {\n  background: #ffffff;\n  border-radius: 10px;\n  border: 1px solid;\n  border-color: #edeceb;\n  padding: 20px;\n  transition: box-shadow 150ms ease, background-color 150ms ease;\n  margin-bottom: 0;\n}\n.Cards-compact-3uwU3 {\n  --cardPadding: calc(20px * 0.5);\n  padding: var(--cardPadding);\n  margin: 0;\n}\n.Cards-interactiveEnabled-2hgE2:hover {\n  background-color: #e6f3ff;\n}\n.Cards-interactiveEnabled-2hgE2:focus,\na.Cards-interactiveEnabled-2hgE2:focus,\n.Cards-interactiveEnabled-2hgE2:hover {\n  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);\n}\n.Cards-interactiveEnabled-2hgE2:focus,\na.Cards-interactiveEnabled-2hgE2:focus {\n  border-color: #0071d4;\n  outline: none;\n  text-decoration: none;\n}\n.Cards-interactiveEnabled-2hgE2:active,\na.Cards-interactiveEnabled-2hgE2:active {\n  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);\n}\n.Cards-interactiveDisabled-1vo5e {\n  background-color: #f5f4f3;\n  color: #c7c6c5;\n  outline: none;\n  cursor: default;\n  border-color: #edeceb;\n}\n.Cards-interactiveDisabled-1vo5e img {\n  filter: grayscale(100%);\n}\n", ""]);
// Exports
exports.locals = {
	"interactive": "Cards-interactive-5A3Jl",
	"compact": "Cards-compact-3uwU3",
	"interactiveEnabled": "Cards-interactiveEnabled-2hgE2",
	"interactiveDisabled": "Cards-interactiveDisabled-1vo5e"
};
module.exports = exports;
