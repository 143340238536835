// organize-imports-ignore
import './style.static.less';
import '../base';
import { loadCurrentUser } from './react/services/CurrentUser';
import { loadCompanyDesign } from './design';
import { renderInitialLoadingError } from './render-error';
(async () => {
    loadCompanyDesign();
    try {
        await loadCurrentUser();
        await import(/* webpackChunkName: 'si-app-main', webpackPreload: true */ './index');
    }
    catch (error) {
        renderInitialLoadingError(error);
    }
})();
