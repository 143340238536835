import { API_URL } from '../../../app-config/constants';
import { get, post, put } from '../request';
// not using ladda's idFrom because that currently doesn't work with COMMAND or UPDATE
const addMissingFields = (response) => {
    if (response) {
        return Object.assign(Object.assign({}, response), { id: response.userId });
    }
    else {
        return {
            id: '',
        };
    }
};
createOnboardingSettings.operation = 'NO_OPERATION';
export function createOnboardingSettings(request) {
    const postData = Object.assign({}, request);
    return post(`${API_URL}/onboarding-survey/`, { postData: postData });
}
updateOnboardingSettings.operation = 'COMMAND';
export function updateOnboardingSettings(request) {
    const postData = Object.assign({}, request);
    return put(`${API_URL}/onboarding-survey/update/`, { postData: postData }).then(addMissingFields);
}
getOnboardingSettings.operation = 'NO_OPERATION';
export function getOnboardingSettings() {
    return get(`${API_URL}/onboarding-survey/`).then(addMissingFields);
}
getCompanyOnboardingSettingsCount.operation = 'NO_OPERATION';
export function getCompanyOnboardingSettingsCount() {
    return get(`${API_URL}/onboarding-survey/count/`);
}
getCompanySizeFilled.operation = 'NO_OPERATION';
export function getCompanySizeFilled() {
    return get(`${API_URL}/onboarding-survey/company-size/`);
}
showAllFeatures.operation = 'COMMAND';
export function showAllFeatures() {
    return put(`${API_URL}/onboarding-survey/show-all-features/`).then(addMissingFields);
}
